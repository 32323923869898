<!--
 * @Author: your name
 * @Date: 2021-04-19 21:01:32
 * @LastEditTime: 2021-05-13 19:47:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\shareresume\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation></navigation>
    <div class="topmsges">
      <div class="topmsg">
        <div>推荐简历</div>
      </div>
      <div class="buzh">
        <div class="hengxian"></div>
        <div class="dianxu">
          <div>1</div>
          <div>2</div>
          <div>3</div>
        </div>
      </div>
    </div>
    <div class="themsg">
      <div class="inpmsg">
        <div>
          <label for="nameid">请输入候选人手机号<span class="xh">*</span></label>
        </div>
        <div>
          <input
            autocomplete="off"
            type="number"
            name=""
            id="nameid"
            v-model="mobile"
            placeholder="请输入您的手机号"
          />
        </div>
      </div>
      <div class="hengxian2"></div>
      <div class="inpmsg">
        <div>
          <label for="nameid">候选人<span class="xh">*</span></label>
        </div>
        <div>
          <input
            autocomplete="off"
            v-model="allmsg.name"
            type="text"
            name=""
            id="nameid"
            placeholder="请输入您的姓名"
          />
        </div>
      </div>
      <div class="hengxian2"></div>
      <div class="inpmsg">
        <div><label for="nameid">身份证</label></div>
        <div>
          <input
            autocomplete="off"
            type="number"
            v-model="allmsg.id_no"
            name=""
            id="nameid"
            class="inidcard"
            placeholder="请输入您的身份证号"
          />
        </div>
      </div>
      <div class="hengxian2"></div>

      <div class="tic">简历上传</div>
      <div>
        <label v-show="!resume" class="selefile" for="infile"
          ><van-icon size=".3rem" name="plus" />选择文件</label
        >
        <input @change="handleFiles()" class="fcl" id="infile" type="file" />
        <div v-show="resume" class="msgff">
          已上传文件：<span
            >1个<span class="seefileclass" @click="seefile(resume)">查看附件</span><span class="clearmsg" @click="resume = ''">删除</span></span
          >
        </div>
      </div>
    </div>
    <div class="nomsgclass"></div>
    <div class="fenxiang">
      <button :style="sharestyle" @click="nextshare">下一步</button>
    </div>
  </div>
</template>
<script>
import uploadfile from "../../../util/upfilejs";
export default {
  data() {
    return {
      allmsg: {},
      resume: "", //上传文件
      mobile: "", //手机号
      sharestyle: "",
      canToNextPage: false,
    };
  },
  created() {
    this.updateBottonNext(false);
    
    if (this.$route.query.isaddshare) {
      this.allmsg.name = sessionStorage.getItem("addshare_name");
      this.allmsg.id_no = sessionStorage.getItem("addshare_id_no");
      this.resume = sessionStorage.getItem("addshare_resume");
      this.mobile = sessionStorage.getItem("addshare_mobile");
    }
    if (this.$route.query.msg != undefined) {
      this.allmsg = JSON.parse(this.$route.query.msg);
      this.resume = this.allmsg.resume;
      this.mobile = this.allmsg.mobile;
    }
  },
  watch: {
    mobile(oldmsg) {
      if ((oldmsg + "").length > 10) {
        this.mobile = (oldmsg + "").substr(0, 11);
        this.getphonemeth();
      }
    },
  },
  mounted() {},
  methods: {
    //查看附件
    seefile(url){
      window.location.href = url;
    },
    //查看手机号是否上传过
    getphonemeth() {
      this.$http
        .post("/firm/v1/Resume/resumeExists", {
          reqType: "resume",
          mobile: this.mobile,
        })
        .then((res) => {
          this.updateBottonNext(true);
        });
    },
    //点击下一步
    nextshare() {
      this.upmsg();
    },
    handleFiles(msg) {
      let a = document.getElementById("infile");
      uploadfile(a.files[0]).then((res) => {
        if (res != "") {
          this.resume = res;
        }
      });
    },
    //是否是新增能转介绍（缓存数据）
    addsharemeth() {
      sessionStorage.setItem("addshare_name", this.allmsg.name);
      sessionStorage.setItem("addshare_id_no", this.allmsg.id_no);
      sessionStorage.setItem("addshare_mobile", this.mobile);
      sessionStorage.setItem("addshare_resume",this.resume);
    },

    /**
     * 修改下一步按钮样式
     * 修改下一步值
     * in(Boolean)
     */
    updateBottonNext(boo) {
      if (boo) {
        this.sharestyle = "";
        this.canToNextPage = true;
      } else {
        this.canToNextPage = false;
        this.sharestyle = "background:#dededf";
      }
      return "";
    },

    upmsg() {
      if (!this.canToNextPage) return;
      this.$toast.loading();
      if (!/^1[3-9]\d{9}$/.test(this.mobile)) {
        this.$toast("请输入正确手机号");
        return;
      }
      if (
        !/^[\u4e00-\u9fa5]+$/gi.test(this.allmsg.name) ||
        (this.allmsg.name + "").length < 1
      ) {
        this.$toast("请输入正确姓名");
        return;
      }
      if (
        (this.allmsg.id_no + "").length < 16 &&
        (this.allmsg.id_no + "").length > 19 &&
        this.allmsg.id_no != "" &&
        this.allmsg.id_no != null
      ) {
        this.$toast("请输入正确身份证号码");
        return;
      }
      //是否是新增能转介绍
      if (this.$route.query.isaddshare) {
        this.addsharemeth();
      }
      this.$http
        .post("/firm/v1/resume/add", {
          reqType: "resume",
          name: this.allmsg.name,
          resume: this.resume,
          id_no: this.allmsg.id_no,
          mobile: this.mobile,
          l_id: this.allmsg.id ? this.allmsg.id : "",
          share_type: this.allmsg.share_type,
        })
        .then((res) => {
          this.$toast.clear();
          if (JSON.parse(res.data).code == 0) {
            if (!this.allmsg.id) {
              this.allmsg.id = JSON.parse(res.data).data;
              this.allmsg.mobile = this.mobile;
            }

            this.$router.push({
              path: "/shareresume2",
              query: {
                msg: JSON.stringify(this.allmsg),
              },
            });
          } else {
            this.$toast(JSON.parse(res.data).msg);
          }
        });
    },
  },
};
</script>
<style scoped>
.seefileclass{
  margin-left: .3rem;
}
.msgff .clearmsg {
  margin-left: 0.3rem;
  color: #ff5f19;
}
.xh {
  color: #ff1f1f;
  font-size: 0.3rem;
  margin-left: 0.05rem;
}
.inidcard {
  width: 100%;
}
.msgff {
  word-wrap: break-word;
  font-size: 0.35rem;
}
.msgff span {
  color: #38f;
}
.selefile {
  font-size: 0.3rem;
  background-color: #38f;
  color: #ffffff;
  padding: 0.2rem 0.4rem;
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
  border-radius: 0.1rem;
}
.fcl {
  visibility: hidden;
  margin-top: 0.35rem;
}
.tic {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  line-height: 0.48rem;
}
.hengxian2 {
  height: 1px;
  background-color: #d6d6d6;
  margin-top: 0.4rem;
  margin-bottom: 0.55rem;
}
.inpmsg > :first-child {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  line-height: 0.48rem;
}
.inpmsg > :nth-child(2) {
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.48rem;
}
.inpmsg input {
  border: none;
  margin-top: 0.15rem;
}
.themsg {
  margin: 0 0.4rem;
  margin-top: 0.49rem;
}
.tiaojian {
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  border-radius: 50%;
  background-color: #ff5f19;
  color: #ffffff;
  font-size: 0.2rem;
  top: -0.1rem;
  right: -0.1rem;
}
.nomsgclass {
  height: 2rem;
}
.fenxiang {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  padding: 0.29rem 0;
  background-color: #ffffff;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  border-top-width: 1px;
}
.fenxiang button {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  border: none;
  padding: 0.28rem 0;
  text-align: center;
  width: 90%;
  margin-left: 5%;
}
.allmsgclass >>> .van-popup__close-icon {
  color: #141f33;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.msgclassse {
  margin: 0.35rem 0.32rem;
}
.jycla {
  font-size: 0.38rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
}
.paixu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.47rem;
  margin-bottom: 0.76rem;
}
.paixu div {
  width: 2.09rem;
  text-align: center;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 0.42rem;
  background: #f7f7f7;
  border-radius: 0.08rem;
  padding: 0.21rem 0;
  margin-bottom: 0.27rem;
}

.buttonclass {
  position: fixed;
  bottom: 0;
  padding: 0.3rem 5% 0.18rem 5%;
  width: 90%;
  box-shadow: 0 -0.05rem 0.1rem rgb(243, 241, 241);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}
.bu1 {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 0.42rem;
  background: #f7f7f7;
  border-radius: 0.16rem;
  border: none;
  padding: 0.27rem 1rem;
}
.bu2 {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  border-radius: 0.16rem;
  border: none;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  padding: 0.27rem 1.5rem;
}
.biaot {
  text-align: center;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
  margin-top: 0.3rem;
}
.pjcla {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a8a8a8;
  line-height: 0.33rem;
}
.pjcla > :nth-child(2) {
  color: #222222;
}
.mmcl5 {
  display: flex;
  align-items: center;
}
.mmcl5 > div:first-child {
  font-size: 0.18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.25rem;
  background: #5fcd86;
  border-radius: 0.08rem;
  padding: 0.05rem 0.1rem;
  margin-right: 0.11rem;
}
.mmcl5 > div:nth-child(2) {
  font-size: 0.18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.25rem;
  background: #96a5aa;
  border-radius: 0.08rem;
  padding: 0.05rem 0.1rem;
}
.mmcl4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.22rem;
}
.mmcl3 {
  width: 100%;
}
.mmcl2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.mmcl2 > div:first-child > :first-child {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 0.42rem;
  margin-right: 0.09rem;
}
.mmcl2 > div:first-child > :nth-child(2) {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cccccc;
  line-height: 0.33rem;
}
.mmcl2 > div:nth-child(2) {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a8a8a8;
  line-height: 0.33rem;
}
.mmcl1 {
  display: flex;
  align-items: center;
  width: 90%;
}
.mmcl1 img {
  min-width: 1.07rem;
  height: 1.07rem;
  margin-right: 0.3rem;
}
.neicla {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.46rem 0.24rem;
  margin-bottom: 0.13rem;
  position: relative;
}
.seimg {
  position: absolute;
  width: 0.31rem;
  height: 0.31rem;
  top: 0.32rem;
  left: 0.32rem;
}
.msgcl {
  margin: 0.17rem 0.24rem;
}
.topmsges {
  background-color: #ffffff;
  padding-bottom: 1.3rem;
}
.buzh {
  margin: 0 1.12rem;
  position: relative;
  margin-top: 0.39rem;
  background-color: #ffffff;
}
.hengxian {
  height: 0.08rem;
  background: linear-gradient(270deg, #ffe2d5 0%, #ff5f19 100%);
  position: absolute;
  width: 100%;
  top: 0.16rem;
  z-index: 1;
}
.dianxu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  line-height: 0.4rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.38rem;
}
.dianxu > div {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: #ff5f19;
  z-index: 2;
}
.dianxu > div:nth-child(2) {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: #ffe2d5;
  z-index: 2;
}
.dianxu > div:nth-child(3) {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: #ffe2d5;
  z-index: 2;
}
.dianxu > div:first-child::after {
  content: "上传简历";
  display: block;
  width: 2rem;
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.38rem;
}
.dianxu > div:nth-child(2):after {
  content: "完善简历";
  display: block;
  width: 2rem;
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #6f6f6f;
  line-height: 0.38rem;
}
.dianxu > div:nth-child(3):after {
  content: "完成简历推荐";
  display: block;
  width: 2rem;
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #6f6f6f;
  line-height: 0.38rem;
}
.topmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.38rem;
  padding-top: 0.16rem;
  background-color: #ffffff;
}
.topmsg > :first-child {
  font-size: 0.46rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.65rem;
}
.sh2 {
  padding: 0.13rem 0.32rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.42rem;
  background: #eeeeee;
  border-radius: 0.1rem;
  position: relative;
}
.sh2 img {
  position: absolute;
  width: 0.12rem;
  bottom: 0.11rem;
  right: 0.13rem;
}
</style>
