/*
 * @Author: your name
 * @Date: 2021-04-26 14:53:11
 * @LastEditTime: 2021-05-10 18:30:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\util\upfilejs.js
 */
import axios from "axios";
import base from "./base";
import { Toast } from 'vant';
let upfilemeth = function (file) {
  return new Promise(function (resolve, reject) {
    Toast.loading({
      message: '上传中...',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0, // 持续展示 toast
    });
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("accessToken", localStorage.getItem("accessToken"));
    formdata.append("user_id", localStorage.getItem("userId"));
    formdata.append("token", localStorage.getItem("token"));
    formdata.append("requestParams[reqType]", "userinfo");
    formdata.append("requestParams[type]", "1");
    formdata.append("requestParams[platform]", "2");
    formdata.append("requestParams[c_id]", "1");
    axios.defaults.headers.post["X-Requested-With"] = "xmlhttprequest";

    axios
      .post(base.formal + "/firm/v1/upload/index", formdata)
      .then((res) => {
        Toast.clear();
        resolve(JSON.parse(res.data).data);
      })
      .catch((res) => { reject("upload file of error") });
  })
}
export default upfilemeth;